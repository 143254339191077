<template>
  <div class="has-nav-bar view-workticket">
    <WorkticketHeader title="安全作业许可管理"></WorkticketHeader>

    <!-- <van-cell
      v-for="(item, index) in entriesList"
      :key="index"
      :title="item.name"
      :icon="require(`@/assets/images/${item.icon}.png`)"
      is-link
      :to="item.route"
    /> -->
    <van-cell
      v-for="(item, index) in menuList"
      :key="index"
      :class="item.icon"
      :title="item.name"
      :icon="item.icon"
      icon-prefix="iconfont"
      is-link
      :to="item.route"
    />
  </div>
</template>

<script>
import WorkticketHeader from "@/components/header/WorkticketHeader";
import { mapState } from "vuex";
export default {
  name: "WorkticketHome",
  components: { WorkticketHeader },
  data() {
    return {
      // entriesList: [
      //   {
      //     name: "我的作业许可",
      //     icon: "workticket-mine",
      //     route: "/workticket/mine"
      //   },
      //   {
      //     name: "作业许可查询",
      //     icon: "workticket-search",
      //     route: "/workticket"
      //   },
      //   {
      //     name: "气体检测",
      //     icon: "workticket-check",
      //     route: "/workticket/checkgas"
      //   }
      // ]
      entriesList: [
        {
          name: "作业活动管理",
          icon: "JSA",
          route: "/workbeforesafetyanalyze"
        },
        {
          name: "我的作业许可",
          icon: "zuoyexuke",
          route: "/workticket/mine"
        },
        {
          name: "作业许可查询",
          icon: "zuoyexukechaxun",
          route: "/workticket"
        },
        {
          name: "气体检测",
          icon: "qitijiance",
          route: "/workticket/checkgas"
        },
        {
          name: "作业监督检查",
          auth: "work-inspect",
          icon: "jiandujiancha",
          route: "/work-inspect"
        },
        {
          name: "监督检查记录",
          auth: "work-inspect-record",
          icon: "jianchajilu",
          route: "/work-inspect-record"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      authRoutes: state => state.menu.roles
    }),
    menuList() {
      return this.entriesList.filter(item => {
        return !item.auth || this.authRoutes.includes(item.auth);
      });
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.view-workticket {
  // .JSA {
  //   border-bottom: 8px solid #eceef2;
  // }
  .JSA::after {
    position: absolute;
    // box-sizing: border-box;
    content: " ";
    pointer-events: none;
    bottom: 0;
    left: 0;
    height: 8px;
    width: 100%;
    background-color: #eceef2;
  }
  .iconfont-JSA {
    color: #f46b6b;
  }
  .iconfont-zuoyexuke {
    color: #f55f5f;
  }
  .iconfont-zuoyexukechaxun {
    color: #f3961c;
  }
  .iconfont-qitijiance {
    color: #1ebaaf;
  }
  .iconfont-jianchajilu {
    color: #1eb6cd;
  }
  .iconfont-jiandujiancha {
    color: #f46b6b;
  }
}
</style>
